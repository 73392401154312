import axios from "axios";

let baseURL="";
if (location.hostname == "localhost" || location.hostname == "localhost") {
  baseURL = "http://localhost:4001"
} else {
  baseURL = "/"
}

export default axios.create({
  baseURL: baseURL
});